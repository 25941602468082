import {
  CHECKBOX_LEF, SELECT_INPUT,
  TEXT_INPUT,
} from '@/views/components/DynamicForm/constants'
import { can } from '@core/libs/acl/utils'

export default function config() {
  const ACCESS_ABILITY_SUBSTITUTES = { action: 'change', subject: 'Substitutes' }
  const tableColumns = [
    {
      key: 'action',
      label: '',
      thStyle: { width: can(ACCESS_ABILITY_SUBSTITUTES.action, ACCESS_ABILITY_SUBSTITUTES.subject) ? '10%' : '0%' },
    },
    {
      key: 'sku',
      label: 'SKU',
      thStyle: { width: '100px' },
    },
    {
      key: 'name',
      label: 'Name',
      thStyle: { width: '400px' },
    },
    {
      key: 'category',
      label: 'Category',
      thStyle: { width: '200px' },
    },
    {
      key: 'pivot.is_direct_substitute',
      label: 'Is Direct Substitute',
      thStyle: { textAlign: 'left' },
    },
  ]
  const fields = {
    pricing_rule_id: {
      type: SELECT_INPUT,
      label: 'Pricing Rule',
      store: 'pricingRulesList',
      rules: 'required',
      // firstSelect: true,
    },
    pricing_period_days: {
      type: TEXT_INPUT,
      label: 'Pricing Period (days) override',
      // firstSelect: true,
      rules: 'numeric',
    },
    status: {
      type: CHECKBOX_LEF,
      label: 'Status',
    },
  }
  const autoSuggestTableColumns = [
    {
      key: 'sku',
      label: 'SKU',
    },
    {
      key: 'name',
      label: 'Name',
    },
    {
      key: 'categories',
      label: 'Category',
      getValue(data) {
        return data.map(category => category.name).join(', ')
      },
    },
    {
      key: 'pivot.is_direct_substitute',
      label: 'Is Direct Substitute',
    },
  ]
  return {
    fields,
    tableColumns,
    autoSuggestTableColumns,
    ACCESS_ABILITY_SUBSTITUTES,
  }
}
